import React from "react";
import { graphql } from "gatsby";
import SEO from "components/seo";
import Layout from "components/layout";
import { MDXRenderer } from "gatsby-plugin-mdx";

interface MdxNode {
  id: string;
  fields: {
    slug: string;
  };
  frontmatter: {
    title: string;
    description?: string;
    from: string;
    featured: boolean;
    active: boolean;
    url?: string;
  };
  body: string;
}

interface ProjectsProps {
  path: string;
  data: {
    allMdx: {
      nodes: MdxNode[];
    };
  };
}

const Projects: React.FC<ProjectsProps> = ({ data }) => {
  const {
    allMdx: { nodes },
  } = data;

  return (
    <>
      <SEO title="Projects" />
      <Layout>
        <div className="container prose prose-list dark:prose-dark mx-auto p-6">
          <h1 className="font-serif">Projects</h1>
          <p className="mt-0 mb-10 pb-3">Side projects I work on.</p>
          <div className="divide-y divide-light-blue-400 dark:divide-gray-500">
            {nodes.map(node => (
              <div key={node.id} className="py-10">
                <a href={node.frontmatter.url}>
                  <h2>{node.frontmatter.title}</h2>
                </a>
                <MDXRenderer>{node.body}</MDXRenderer>
              </div>
            ))}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Projects;

export const query = graphql`
  query AllPublishedProjects {
    allMdx(
      filter: {
        frontmatter: { draft: { ne: true } }
        fields: { collection: { eq: "projects" } }
      }
    ) {
      nodes {
        id
        fields {
          slug
        }
        frontmatter {
          title
          description
          from
          featured
          active
          url
        }
        body
      }
    }
  }
`;
